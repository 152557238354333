import * as React from 'react';

const setToken = (token: string) => {
  if (!token) {
    throw new Error('Token is required');
  }

  localStorage.setItem(_getTokenKey(), token);
};

const getToken = () => {
  return localStorage.getItem(_getTokenKey());
};

const clearToken = () => {
  localStorage.removeItem(_getTokenKey());
  localStorage.removeItem(_getRefreshTokenKey());
};

const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(_getRefreshTokenKey(), refreshToken);
};

const getRefreshToken = () => {
  return localStorage.getItem(_getRefreshTokenKey());
};

const clearRefreshToken = () => {
  localStorage.removeItem(_getRefreshTokenKey());
};

const isAuthenticated = () => {
  return !!getToken();
};

const _getTokenKey = () => {
  return 'knock_jwtToken';
};

const _getRefreshTokenKey = () => {
  return 'knock_jwtToken_refresh';
};

const AuthFunctions = {
  setToken,
  getToken,
  clearToken,
  setRefreshToken,
  getRefreshToken,
  clearRefreshToken,
  isAuthenticated,
};

type AuthContextValues = typeof AuthFunctions & { internalMode: boolean };

// TODO: Confirm whether the internalMode flag is actually needed, if not remove it
const internalMode = false;
const AuthContext = React.createContext<AuthContextValues>({
  ...AuthFunctions,
  internalMode,
});
const { Provider, Consumer: AuthConsumer } = AuthContext;

const AuthProvider: React.FC = ({ children }) => {
  const location = new URLSearchParams(document.location.search);
  const internalMode = location.get('internal') === 'true' || false;

  return (
    <Provider value={{ ...AuthFunctions, internalMode }}>{children}</Provider>
  );
};

export { AuthContext, AuthConsumer, AuthProvider, AuthFunctions };

import React, { FC } from 'react';
import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButtonGroupProps as MuiToggleButtonGroupProps,
} from '@material-ui/lab';

export interface ToggleButtonGroupProps extends MuiToggleButtonGroupProps {}

export const ToggleButtonGroup: FC<ToggleButtonGroupProps> = (props) => {
  return <MuiToggleButtonGroup {...props} />;
};

import React, { FC } from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';

import { shadows } from '../Shadows';
import { palette, white, slate, denim, utility } from '../Colors';

const defaultFontFamily = '"Open Sans", "Helvetica", "Arial", sans-serif';

export const knockTheme = createTheme({
  palette,
  typography: {
    fontFamily: defaultFontFamily,
    fontSize: 14,

    h1: {
      fontWeight: 300,
      fontSize: '4em',
      lineHeight: '125%',
      letterSpacing: '-1.5px',
    },

    h2: {
      fontWeight: 300,
      fontSize: '2.8125em',
      lineHeight: '125%',
      letterSpacing: '-0.5px',
    },

    h3: {
      fontWeight: 400,
      fontSize: '2.375em',
      lineHeight: '125%',
    },

    h4: {
      fontWeight: 400,
      fontSize: '2em',
      lineHeight: '125%',
    },

    h5: {
      fontWeight: 400,
      fontSize: '1.625em',
      lineHeight: '124%',
      letterSpacing: '0.18px',
    },

    h6: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
    },

    subtitle1: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.2px',
    },

    subtitle2: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },

    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
    },

    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },

    button: {
      fontWeight: 600,
      textTransform: 'unset',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },

    caption: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },

    overline: {
      fontWeight: 700,
      fontSize: '12px',
      textTransform: 'uppercase',
      lineHeight: '16px',
      letterSpacing: '1.25px',
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        color: palette.text.primary,
        fontFamily: defaultFontFamily,
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.25px',
        lineHeight: '20px',
      },
    },

    MuiAvatar: {
      root: {
        height: '40px',
        width: '40px',
      },
    },

    MuiCard: {
      root: {
        borderRadius: '8px',
      },
    },

    MuiCardHeader: {
      root: {
        padding: '16px',
      },

      action: {
        marginTop: '0px',
      },
    },

    MuiCardContent: {
      root: {
        padding: '16px',
      },
    },

    MuiCardActions: {
      root: {
        padding: '16px',
      },
    },

    MuiCircularProgress: {
      circle: {
        strokeLinecap: 'round',
      },
    },

    MuiFormControl: {
      root: {
        marginBottom: '1rem',
        '&:not(:last-child)': {
          marginRight: '6px',
        },
      },
    },

    MuiFormHelperText: {
      root: {
        marginLeft: '5px',
        color: slate[500],

        '&.Mui-disabled': {
          color: slate[300],
        },
      },

      contained: {
        marginLeft: '5px',
      },
    },

    MuiFormLabel: {
      root: {
        color: slate[600],
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.1px',
        transitionProperty: 'color',
        transitionDuration: '500ms',

        '&.Mui-focused': {
          color: slate[500],
        },

        '&.Mui-disabled': {
          color: slate[300],
        },
      },
    },

    MuiInput: {
      underline: {
        '&:after': {
          border: 'none !important',
        },

        '&:before': {
          border: 'none !important',
        },
      },
    },

    MuiInputBase: {
      root: {
        color: slate[700],
        backgroundColor: white,
        border: `2px solid ${slate[300]}`,
        borderRadius: '2px',
        outlineColor: slate[500],
        paddingLeft: '12px',

        '&.Mui-focused': {
          border: `2px solid ${denim[300]}`,
          borderRadius: '2px',
        },

        '&.Mui-error': {
          border: '2px solid #F599A8',
        },

        '&.Mui-disabled': {
          backgroundColor: slate[100],
        },
      },

      input: {
        padding: '9px 0 9px',
        lineHeight: '22px',
        height: '22px',
      },

      formControl: {
        backgroundColor: white,
        borderColor: slate[300],
        outlineColor: slate[300],
        marginTop: '3px !important',
        position: 'relative',
      },

      inputTypeSearch: {
        backgroundColor: white,
        borderColor: slate[500],
        outlineColor: slate[500],
      },

      adornedEnd: {
        paddingRight: '5px',
      },
    },

    MuiInputLabel: {
      root: {
        color: slate[600],
        zIndex: 1,

        '& .MuiTypography-root:not(.MuiTypography-caption)': {
          fontSize: '14px',
          lineHeight: '19px',
          letterSpacing: '0.1px',
        },
      },

      formControl: {
        color: slate[600],
        marginTop: '4px',
        left: '5px',
        position: 'relative',
      },

      shrink: {
        transform: 'translate(0, 0.0px) scale(1.0)',
      },
    },

    MuiLinearProgress: {
      root: {
        height: '8px',
        borderRadius: '4px',
      },

      colorPrimary: {
        backgroundColor: '#e2e6eb',
      },

      barColorPrimary: {
        borderRadius: '4px',
        backgroundColor: palette.primary.main,
      },
    },

    MuiListItemText: {
      primary: {
        color: palette.text.primary,
      },
    },

    MuiMenuItem: {
      root: {
        '&:focus, &:hover, &.Mui-selected, &.Mui-selected:hover': {
          backgroundColor: utility.activeBackground,
        },
      },
    },

    MuiListItem: {
      root: {
        '&.MuiListItem-button:hover': {
          backgroundColor: utility.activeBackground,
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        paddingLeft: '12px',
        borderRadius: '2px',
      },

      input: {
        backgroundColor: white,
        padding: '9px 0 9px',
        lineHeight: '22px',
        height: '22px',
      },

      notchedOutline: {
        border: 'none',
      },
    },

    MuiRadio: {
      root: {
        color: slate[500],
      },
    },

    MuiSvgIcon: {
      root: {
        zIndex: 1,
      },
    },

    MuiSwitch: {
      colorPrimary: {
        color: utility.grey98,

        '&$checked': {
          color: denim[500],
        },

        '&$disabled': {
          color: slate[500],
        },
      },
    },

    MuiTooltip: {
      tooltip: {
        backgroundColor: slate[800],
        padding: '10px',
        color: white,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.4px',
      },

      arrow: {
        color: slate[800],
      },
    },
  },
  shadows,
});

export const ThemeProvider: FC = ({ children }) => (
  <MuiThemeProvider theme={knockTheme}>{children}</MuiThemeProvider>
);

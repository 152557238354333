import React, { FC } from 'react';
import {
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
} from '@material-ui/core';

export interface DialogContentTextProps extends MuiDialogContentTextProps {}

export const DialogContentText: FC<DialogContentTextProps> = ({
  children,
  ...props
}) => {
  return <MuiDialogContentText {...props}>{children}</MuiDialogContentText>;
};

import * as React from 'react';
import * as MuiCore from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

const expandIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}));

export interface AccordionSummaryProps extends MuiCore.AccordionSummaryProps {
  hideIcon?: boolean;
}

export const AccordionSummary: React.FC<AccordionSummaryProps> = ({
  children,
  hideIcon,
  ...rest
}) => {
  const classes = expandIconStyles();
  const defaultExpandIcon = <ExpandMoreIcon classes={classes} />;
  const props = { ...rest };

  if (!props.expandIcon && !hideIcon) {
    props.expandIcon = defaultExpandIcon;
  }

  return (
    <MuiCore.AccordionSummary {...props}>{children}</MuiCore.AccordionSummary>
  );
};

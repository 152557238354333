import React, { FC } from 'react';
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonProps as MuiToggleButtonProps,
} from '@material-ui/lab';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
}));

export interface ToggleButtonProps extends MuiToggleButtonProps {}

export const ToggleButton: FC<ToggleButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <MuiToggleButton classes={{ root: classes.toggleButton }} {...props} />
  );
};

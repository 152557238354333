import React from 'react';
import * as MuiCore from '@material-ui/core';

// A bug in MUI 4.x causes the onChange event signature for Tabs to be wrong. We omit it here and define one that will work in consuming apps. See
// https://github.com/mui/material-ui/issues/17454.
export interface TabsProps extends Omit<MuiCore.TabsProps, 'onChange'> {
  onChange: (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => void;
  ariaLabel?: string;
}

export const Tabs: React.FC<TabsProps> = ({ children, ariaLabel, ...rest }) => (
  <MuiCore.Tabs {...rest} aria-label={ariaLabel ? ariaLabel : 'tablist'}>
    {children}
  </MuiCore.Tabs>
);

import React, { FC } from 'react';
import { List as MuiList, ListProps as MuiListProps } from '@material-ui/core';

export interface ListProps extends MuiListProps {
  ariaLabel?: string;
}

export const List: FC<ListProps> = ({ children, ariaLabel, ...rest }) => {
  return (
    <MuiList aria-label={ariaLabel ? ariaLabel : 'list'} {...rest}>
      {children}
    </MuiList>
  );
};

import React, { FC } from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';

export type TextFieldProps = MuiTextFieldProps & {};

export const TextField: FC<TextFieldProps> = ({ children, ...props }) => {
  return (
    <MuiTextField
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    >
      {children}
    </MuiTextField>
  );
};

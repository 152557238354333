import React, { FC } from 'react';
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
} from '@material-ui/core';

export interface AvatarProps extends MuiAvatarProps {}

export const Avatar: FC<MuiAvatarProps> = ({ children, ...props }) => {
  return <MuiAvatar {...props}>{children}</MuiAvatar>;
};

import React from 'react';
import * as MuiCore from '@material-ui/core';

export const CardHeader: MuiCore.OverridableCardHeader = ({ ...props }) => {
  return (
    <MuiCore.CardHeader
      titleTypographyProps={{ variant: 'h6' }}
      subheaderTypographyProps={{ variant: 'caption' }}
      {...props}
    />
  );
};

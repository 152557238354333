import * as React from 'react';
import * as MuiCore from '@material-ui/core';

export interface CardActionsProps extends MuiCore.CardActionsProps {}

export const CardActions: React.FC<CardActionsProps> = ({
  children,
  ...rest
}) => {
  return <MuiCore.CardActions {...rest}>{children}</MuiCore.CardActions>;
};

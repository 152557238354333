import * as React from 'react';
import * as MuiCore from '@material-ui/core';

export interface CardMediaProps extends MuiCore.CardMediaProps {}

export const CardMedia: React.FC<CardMediaProps> = ({ children, ...rest }) => {
  return (
    <MuiCore.CardMedia {...rest} data-testid="cardmedia">
      {children}
    </MuiCore.CardMedia>
  );
};

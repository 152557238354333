import React, { ChangeEvent, FC, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  Radio,
} from '@material-ui/core';

import { slate } from '../../theme';

const formLabelStyles = makeStyles(() => ({
  root: {
    height: '21px',
    left: '6px',
    position: 'relative',
  },
}));

const formGroupStyles = makeStyles(() => ({}));

const formControlLabelStyles = makeStyles((theme) => ({
  root: {
    margin: '4px 16px 0 0',

    '& .MuiRadio-root': {
      color: theme.palette.secondary.main,
      marginRight: '8px',
      padding: 0,
    },

    '& .MuiRadio-root.Mui-checked': {
      color: theme.palette.primary.main,
    },

    '& .MuiTypography-root.MuiFormControlLabel-label': {
      color: slate[900],
      fontSize: '14px',
      fontWeight: 'normal',
      letterSpacing: '0.25px',
      lineHeight: '20px',
    },
  },
}));

export interface RadioGroupOption {
  label: string;
  value: string;
}

export interface RadioGroupProps extends Omit<MuiRadioGroupProps, 'onChange'> {
  error?: boolean;
  label?: string;
  options?: RadioGroupOption[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  requiredMessage?: string;
}

export const RadioGroup: FC<RadioGroupProps> = ({
  error,
  label,
  options,
  children,
  onChange,
  requiredMessage,
  style,
  ...props
}) => {
  const [value, setValue] = useState(props.defaultValue || props.value || '');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setValue(value);
    onChange && onChange(event);
  };

  const hasError = error || (!!requiredMessage && !value);

  return (
    <FormControl component="div" style={style} error={hasError}>
      {label && (
        <FormLabel component="label" classes={formLabelStyles(props)}>
          {label}
        </FormLabel>
      )}

      <MuiRadioGroup
        {...props}
        value={value}
        onChange={handleChange}
        aria-label={label || ''}
        classes={formGroupStyles(props)}
      >
        {options &&
          options.map((option, index) => (
            <FormControlLabel
              id={option.label}
              value={option.value}
              control={<Radio aria-checked={value === option.value} />}
              label={option.label}
              classes={formControlLabelStyles(props)}
              key={`${index}-${option.value}`}
            />
          ))}
        {children}
      </MuiRadioGroup>

      {hasError && <FormHelperText>{requiredMessage}</FormHelperText>}
    </FormControl>
  );
};

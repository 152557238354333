import React, { FC } from 'react';
import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
} from '@material-ui/core';

export interface ListItemTextProps extends MuiListItemTextProps {}

export const ListItemText: FC<ListItemTextProps> = ({ children, ...props }) => {
  return <MuiListItemText {...props}>{children}</MuiListItemText>;
};

import React, { FC } from 'react';
import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
} from '@material-ui/core';

export interface DrawerProps extends MuiDrawerProps {}

export const Drawer: FC<DrawerProps> = ({ children, ...props }) => {
  return <MuiDrawer {...props}>{children}</MuiDrawer>;
};

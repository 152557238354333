import * as React from 'react';
import * as MuiCore from '@material-ui/core';

export interface AccordionProps extends MuiCore.AccordionProps {}

export const Accordion: React.FC<AccordionProps> = ({ children, ...rest }) => {
  return (
    // Accordions should only have AccordionSummary and AccordionDetails as children
    <MuiCore.Accordion {...rest}>{children}</MuiCore.Accordion>
  );
};

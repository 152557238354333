import React, { FC } from 'react';
import {
  Backdrop as MuiBackdrop,
  BackdropProps as MuiBackdropProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  defaultStyles: {
    zIndex: 99,
  },
});

export interface BackdropProps extends MuiBackdropProps {}

export const Backdrop: FC<BackdropProps> = ({ children, ...props }) => {
  const classes = useStyles();
  const { className } = props;

  return (
    <MuiBackdrop
      {...props}
      classes={{
        root: classes.defaultStyles + (className ? ' ' + className : ''),
      }}
      aria-label="Backdrop"
    >
      {children}
    </MuiBackdrop>
  );
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Backdrop } from '../Backdrop';
import { CircularProgress } from '../CircularProgress';

const useStyles = makeStyles({
  progressIndicator: {
    color: '#fff',
  },
});

export interface LoadingOverlayProps {
  className?: string;
  invisible?: boolean;
  open: boolean;
  progressClassName?: string;
  style?: React.CSSProperties;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  progressClassName,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Backdrop {...rest}>
      <CircularProgress
        className={
          classes.progressIndicator +
          (progressClassName ? ' ' + progressClassName : '')
        }
      />
    </Backdrop>
  );
};

import React, { FC } from 'react';
import {
  Divider as MuiDivider,
  DividerProps as MuiDividerProps,
} from '@material-ui/core';

export interface DividerProps extends MuiDividerProps {}

export const Divider: FC<DividerProps> = ({ children, ...props }) => {
  return <MuiDivider {...props}>{children}</MuiDivider>;
};

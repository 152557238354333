import React, { useState, FC } from 'react';
import {
  Alert as MaterialAlert,
  AlertProps as MuiAlertProps,
} from '@material-ui/lab';

import { makeStyles, Theme } from '@material-ui/core';

export interface AlertProps extends MuiAlertProps {
  hideIcon?: boolean;
  onDismiss?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    padding: '8px 16px',

    '& .MuiAlert-icon': {
      padding: 0,
    },
  },

  alertMessage: {
    color: theme.palette.text.primary,
    padding: 0,
  },

  defaultBackground: {
    backgroundColor: 'rgba(141, 144, 165, .08)',
  },

  infoBackground: {
    backgroundColor: 'rgba(90, 114, 246, .08)',
  },

  errorBackground: {
    backgroundColor: '#FEEBEA',
  },
}));

export const Alert: FC<AlertProps> = ({
  children,
  severity,
  variant,
  hideIcon,
  onDismiss,
  ...rest
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(true);

  const handleDismiss = () => {
    onDismiss && onDismiss();
    setShow(false);
  };

  if (!show) {
    return null;
  }

  let backgroundClass: string | undefined;

  // We set specific background colors for severity values based on our current designs
  switch (severity) {
    case undefined: {
      backgroundClass = classes.defaultBackground;
      break;
    }
    case 'info': {
      backgroundClass = classes.infoBackground;
      break;
    }
    case 'error': {
      backgroundClass = classes.errorBackground;
      break;
    }
  }

  return (
    <MaterialAlert
      className={backgroundClass}
      severity={severity}
      icon={hideIcon || severity === undefined ? false : undefined}
      classes={{
        root: classes.alert,
        message: classes.alertMessage,
      }}
      onClose={onDismiss ? handleDismiss : undefined}
      {...rest}
    >
      {children}
    </MaterialAlert>
  );
};

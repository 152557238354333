import * as React from 'react';
import {
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
} from '@material-ui/core';

export type BaseMenuItemProps = {
  button?: true;
} & MuiMenuItemProps;

export interface MenuItemProps extends BaseMenuItemProps {
  ariaLabel?: string;
  onClick: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  children,
  onClick,
  ariaLabel,
  ...rest
}) => (
  <MuiMenuItem
    onClick={onClick}
    aria-label={ariaLabel ? ariaLabel : 'menu item'}
    {...rest}
  >
    {children}
  </MuiMenuItem>
);

import React, { FC } from 'react';
import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@material-ui/core';

export interface DialogActionsProps extends MuiDialogActionsProps {}

export const DialogActions: FC<DialogActionsProps> = ({
  children,
  ...props
}) => {
  return <MuiDialogActions {...props}>{children}</MuiDialogActions>;
};

import * as React from 'react';
import * as MuiCore from '@material-ui/core';

export type CardContentProps = MuiCore.CardContentProps & {};

export const CardContent: React.FC<CardContentProps> = ({
  children,
  ...rest
}) => {
  return <MuiCore.CardContent {...rest}>{children}</MuiCore.CardContent>;
};

import * as React from 'react';
import * as MuiCore from '@material-ui/core';

export interface TabProps extends MuiCore.TabProps {
  ariaLabel?: string;
}

export const Tab: React.FC<TabProps> = ({ children, ariaLabel, ...rest }) => (
  <MuiCore.Tab {...rest} aria-label={ariaLabel ? ariaLabel : 'tab'}>
    {children}
  </MuiCore.Tab>
);

import React, { FC } from 'react';
import {
  ButtonProps as MuiButtonProps,
  Button as MuiButton,
} from '@material-ui/core';

export type ButtonProps = MuiButtonProps & {};

export const Button: FC<ButtonProps> = ({
  children,
  color = 'primary',
  variant = 'contained',
  ...rest
}) => {
  return (
    <MuiButton {...rest} color={color} variant={variant}>
      {children}
    </MuiButton>
  );
};

import React from 'react';
import {
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from '@material-ui/core';

export type BaseListItemProps = {
  button?: any;
} & MuiListItemProps;

export interface ListItemProps extends BaseListItemProps {
  ariaLabel?: string;
  disableRipple?: boolean;
  onClick?:
    | ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void)
    | undefined;
}

export const ListItem: React.FC<ListItemProps> = ({
  children,
  onClick,
  ariaLabel,
  ...rest
}) => (
  <MuiListItem
    onClick={onClick}
    aria-label={ariaLabel ? ariaLabel : 'list item'}
    {...rest}
  >
    {children}
  </MuiListItem>
);

import React, { FC } from 'react';
import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core';

export interface SwitchProps extends MuiSwitchProps {}

export const Switch: FC<SwitchProps> = (props) => {
  return <MuiSwitch {...props} />;
};

import * as React from 'react';
import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@material-ui/core';

export interface MenuProps extends MuiMenuProps {
  ariaLabel?: string;
}

export const Menu: React.FC<MenuProps> = ({ children, ariaLabel, ...rest }) => (
  <MuiMenu aria-label={ariaLabel ? ariaLabel : 'menu'} {...rest}>
    {children}
  </MuiMenu>
);

import React, { FC } from 'react';
import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@material-ui/core';

export interface CircularProgressProps extends MuiCircularProgressProps {}

export const CircularProgress: FC<CircularProgressProps> = (props) => (
  <MuiCircularProgress
    {...props}
    aria-label={`progress bar: ${props.value || 0}% complete`}
    data-testid="circular-progress"
  />
);

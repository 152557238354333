import React, { FC } from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  PopperProps,
} from '@material-ui/core';

export interface TooltipProps extends MuiTooltipProps {}

export const Tooltip: FC<TooltipProps> = ({ children, ...props }) => {
  // Uses Popper's API to set the offset from the reference element.
  // https://popper.js.org/docs/v1/#modifiersoffset
  const popperProps: Partial<PopperProps> = {
    modifiers: {
      offset: { offset: '0,4' },
    },
    ...(props.PopperProps || {}),
  };
  return (
    <MuiTooltip {...props} PopperProps={popperProps}>
      {children}
    </MuiTooltip>
  );
};

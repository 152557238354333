import { Palette } from '@material-ui/core/styles/createPalette';

export type ColorWeights =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';

export type AccentWeights = '50' | '200' | '400' | '500';

export type MainColor = {
  [key in ColorWeights]: string;
};

export type AccentColor = {
  [key in AccentWeights]: string;
};

export type ColorNames =
  | 'denim'
  | 'slate'
  | 'apple'
  | 'carrot'
  | 'banana'
  | 'grass'
  | 'sky'
  | 'violet'
  | 'rose';

export const white = '#fff';
export const black = '#000';

export const denim: MainColor = {
  50: '#F5F6FF',
  100: '#EBEEFE',
  200: '#D6DCFF',
  300: '#BDC7FE',
  400: '#97A6FC',
  500: '#697FF7',
  600: '#4A61E8',
  700: '#3649BA',
  800: '#263273',
  900: '#19204C',
};

export const slate: MainColor = {
  50: '#F7F7F8',
  100: '#EEEEF2',
  200: '#E2E3E9',
  300: '#CBCDD8',
  400: '#A9ACBC',
  500: '#8D90A5',
  600: '#70758F',
  700: '#4D5066',
  800: '#353950',
  900: '#292B3D',
};

export const apple: MainColor = {
  50: '#FFF5F6',
  100: '#FFE1E5',
  200: '#FFC2CA',
  300: '#FFFFFF',
  400: '#F41C39',
  500: '#F44336',
  600: '#DB0024',
  700: '#C20013',
  800: '#FFFFFF',
  900: '#70000B',
};

export const carrot: MainColor = {
  50: '#FFF5F0',
  100: '#FFE8DB',
  200: '#FFD5BD',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#FF5900',
  600: '#F04800',
  700: '#C73C00',
  800: '#FFFFFF',
  900: '#852800',
};

export const sky: MainColor = {
  50: '#FFF9EB',
  100: '#E0F2FF',
  200: '#C7E8FF',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#0A93F5',
  600: '#0882DA',
  700: '#0661A3',
  800: '#FFFFFF',
  900: '#033B63',
};

export const banana: MainColor = {
  50: '#FFF9EB',
  100: '#FFF3D6',
  200: '#FFE3A3',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#FFB302',
  600: '#EDA703',
  700: '#D19200',
  800: '#FFFFFF',
  900: '#473200',
};

export const grass: MainColor = {
  50: '#F0FFFA',
  100: '#DAFBF0',
  200: '#B4F3DE',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#0AA974',
  600: '#15986D',
  700: '#067550',
  800: '#FFFFFF',
  900: '#033A28',
};

export const violet: MainColor = {
  50: '#F0EBFF',
  100: '#E8E0FF',
  200: '#E0D6FF',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#9D7EFC',
  600: '#7F57F7',
  700: '#673BED',
  800: '#FFFFFF',
  900: '#2E0F8A',
};

export const rose: MainColor = {
  50: '#FFF5F9',
  100: '#FFE5F1',
  200: '#FFD1E5',
  300: '#FFFFFF',
  400: '#FFFFFF',
  500: '#F55198',
  600: '#EC277D',
  700: '#CC0058',
  800: '#FFFFFF',
  900: '#7A0035',
};

export const utility = {
  activeBackground: 'rgba(90, 114, 246, 0.08)',
  grey98: '#FAFAFA',
};

export const NamedColors: { [key in ColorNames]: MainColor | AccentColor } = {
  denim,
  slate,
  apple,
  carrot,
  banana,
  grass,
  sky,
  violet,
  rose,
};

const paletteObject = {
  primary: {
    light: denim[400],
    main: denim[500],
    dark: denim[600],
    contrastText: white,
  },
  secondary: {
    light: slate[400],
    main: slate[500],
    dark: slate[600],
    contrastText: white,
  },
  success: {
    light: grass[500],
    main: grass[600],
    dark: grass[700],
    contrastText: white,
  },
  info: {
    light: sky[500],
    main: sky[600],
    dark: sky[700],
    contrastText: white,
  },
  warning: {
    light: banana[500],
    main: banana[600],
    dark: banana[700],
    contrastText: banana[900],
  },
  error: {
    light: apple[500],
    main: apple[600],
    dark: apple[700],
    contrastText: white,
  },
  text: {
    primary: 'rgba(0,0,0, 0.87)',
    secondary: 'rgba(0,0,0, 0.64)',
    disabled: 'rgba(0,0,0, 0.72)',
    hint: 'rgba(0,0,0, 0.40)',
  },
  action: {
    active: denim[500],

    activatedOpacity: 0.08,

    hover: denim[500],
    hoverOpacity: 0.08,

    selected: denim[500],
    selectedOpacity: 0.08,

    disabled: '#BDBDBD',
    disabledOpacity: 0.26,

    disabledBackground: '#EEEEEF',

    focus: denim[500],
    focusOpacity: 0.08,
  },
};

type PaletteProperties = keyof typeof paletteObject;

export const palette = paletteObject as Pick<Palette, PaletteProperties>;

import * as React from 'react';
import {
  Popover as MuiPopover,
  PopoverProps as MuiPopoverProps,
} from '@material-ui/core';

export interface PopoverProps extends MuiPopoverProps {
  ariaLabel?: string;
}

export const Popover: React.FC<PopoverProps> = ({
  children,
  ariaLabel,
  ...rest
}) => (
  <MuiPopover aria-label={ariaLabel ? ariaLabel : 'popover'} {...rest}>
    {children}
  </MuiPopover>
);

import React, { ChangeEvent, FC } from 'react';
import {
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles({
  // The !important is needed here because the MUI style being overridden has it
  noLabel: {
    marginTop: '0 !important',
  },

  fullWidth: {
    width: '100%',
  },
});

export type OptionType = {
  value: any;
  label: string;
  disabled?: boolean;
};

export interface SelectProps extends MuiSelectProps {
  options: OptionType[];
  onSelectChange: (value: any) => void;
  label?: string;
  defaultValue?: any;
  fullWidth?: boolean;
  noForm?: boolean;
}

export const Select: FC<SelectProps> = ({
  options,
  onSelectChange,
  label,
  defaultValue = '',
  fullWidth = false,
  noForm = false,
  ...rest
}) => {
  const classes = useStyles();

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    onSelectChange(event.target.value);
  };

  const buildSelectComponent = () => {
    const classNamesArgs: Record<string, boolean> = {};
    classNamesArgs[classes.noLabel] = label === undefined;
    classNamesArgs[classes.fullWidth] = fullWidth;

    return (
      <>
        {label && (
          <InputLabel id={label} shrink={true}>
            {label}
          </InputLabel>
        )}
        <MuiSelect
          onChange={handleChange}
          className={classnames(classNamesArgs)}
          value={defaultValue}
          labelId={label}
          {...rest}
        >
          {options.map(({ value, label, disabled }, i) => (
            <MenuItem key={`${i}-${value}`} value={value} disabled={disabled}>
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
      </>
    );
  };

  return (
    <>
      {noForm ? (
        buildSelectComponent()
      ) : (
        <FormControl fullWidth={fullWidth}>
          {buildSelectComponent()}
        </FormControl>
      )}
    </>
  );
};

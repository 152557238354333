import * as React from 'react';
import * as MuiCore from '@material-ui/core';

export interface InputCheckboxProps extends MuiCore.CheckboxProps {
  label?: string;
}

export const InputCheckbox: React.FC<InputCheckboxProps> = ({
  label,
  ...rest
}) => {
  return (
    <MuiCore.FormControlLabel
      control={
        <MuiCore.Checkbox
          inputProps={{
            'aria-checked': Boolean(rest.checked),
            role: 'checkbox',
          }}
          {...rest}
        />
      }
      label={label}
    />
  );
};

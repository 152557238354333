import React from 'react';
import {
  default as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@material-ui/lab/Autocomplete';
import { makeStyles, Theme } from '@material-ui/core';

import { utility } from '../../theme/Colors';

const useStyles = makeStyles((theme: Theme) => ({
  inputRoot: {
    '&.MuiAutocomplete-inputRoot': {
      paddingTop: '3px',
      paddingBottom: '3px',
    },
  },

  endAdornment: {
    paddingRight: '5px',

    '& .MuiIconButton-root': {
      color: theme.palette.text.secondary,
    },
  },

  option: {
    '&[data-focus="true"], &[aria-selected="true"]': {
      backgroundColor: utility.activeBackground,
    },
  },
}));

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const classes = useStyles();

  return <MuiAutocomplete classes={classes} {...props} />;
}

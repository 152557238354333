import React, { FC } from 'react';
import {
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
  SnackbarOrigin as MuiSnackbarOrigin,
} from '@material-ui/core';

export type SnackbarOrigin = MuiSnackbarOrigin & {};

export type SnackbarProps = MuiSnackbarProps & {};

export const Snackbar: FC<SnackbarProps> = ({ children, ...rest }) => (
  <MuiSnackbar {...rest} arai-label="snackbar message">
    {children}
  </MuiSnackbar>
);

import React, { FC } from 'react';
import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@material-ui/core';

export interface DialogProps extends MuiDialogProps {}

export const Dialog: FC<DialogProps> = ({ children, ...props }) => {
  return <MuiDialog {...props}>{children}</MuiDialog>;
};
